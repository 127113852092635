import { API_DOMAIN } from "../config/config";
import { openAccountModal } from "../pages/account";
import { getCookie } from "../utils/common";

export async function cachedGetWithAuth(url) {
    return cacheMiddlewareForGet(url, () => getCookie("stockservant_token"))
}

export async function getWithAuth(url) {
  const token = getCookie("stockservant_token");
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json, text/plain, */*");
  myHeaders.append("stockservant_token", token);
  var requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  const response = await fetch(`${API_DOMAIN}${url}`, requestOptions);
  const result = await response.json();
  console.log(result);
  if (result.status === 403) {
    openAccountModal?.();
    console.log("auth failed");
  }
  return result;
}

export async function postJson(url, body) {
  const resp = await fetch(`${API_DOMAIN}${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  const result = await resp.json();
  return result;
}

export async function putJson(url, body) {
  const resp = await fetch(`${API_DOMAIN}${url}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  const result = await resp.json();
  return result;
}

// 定义一个缓存对象
const cache = new Map();

// 定义缓存的过期时间（例如1小时）
const CACHE_EXPIRE_TIME = 1 * 60 * 60 * 1000; // 1小时

// 缓存中间件
function cacheMiddleware(url, body, getToken) {
  // 生成缓存键（基于URL和Body）
  const cacheKey = JSON.stringify({ url, body });

  // 检查缓存中是否有数据，并且未过期
  if (cache.has(cacheKey)) {
    const cacheEntry = cache.get(cacheKey);
    if (Date.now() - cacheEntry.timestamp < CACHE_EXPIRE_TIME) {
      return cacheEntry.value;
    }
  }

  // 如果没有缓存或已过期，发送请求
  const token = getToken();
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json, text/plain, */*");
  myHeaders.append("stockservant_token", token);
  myHeaders.append("Content-Type", "application/json");

  return fetch(`${API_DOMAIN}${url}`, {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(body),
  })
    .then((resp) => resp.json())
    .then((data) => {
      // 保存到缓存中
      cache.set(cacheKey, {
        value: data,
        timestamp: Date.now(),
      });
      return data;
    });
}

function cacheMiddlewareForGet(url, getToken) {
    // 生成缓存键（基于URL和Body）
    const cacheKey = url;
  
    // 检查缓存中是否有数据，并且未过期
    if (cache.has(cacheKey)) {
      const cacheEntry = cache.get(cacheKey);
      if (Date.now() - cacheEntry.timestamp < CACHE_EXPIRE_TIME) {
        return cacheEntry.value;
      }
    }
  
    // 如果没有缓存或已过期，发送请求
    const token = getToken();
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json, text/plain, */*");
    myHeaders.append("stockservant_token", token);
    myHeaders.append("Content-Type", "application/json");
  
    return fetch(`${API_DOMAIN}${url}`, {
      method: "GET",
      headers: myHeaders,
    })
      .then((resp) => resp.json())
      .then((data) => {
        // 保存到缓存中
        cache.set(cacheKey, {
          value: data,
          timestamp: Date.now(),
        });
        return data;
      });
  }

// 带缓存的postJsonWithAuth函数
export async function cachedPostJsonWithAuth(url, body) {
  return cacheMiddleware(url, body, () => getCookie("stockservant_token"));
}

export async function postJsonWithAuth(url, body) {
    const token = getCookie("stockservant_token");
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json, text/plain, */*");
    myHeaders.append("stockservant_token", token);
    myHeaders.append("Content-Type", "application/json")
    const resp = await fetch(`${API_DOMAIN}${url}`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(body),
    });
    const result = await resp.json();
    return result;
  }


export async function putWithAuth(url, body) {
    const token = getCookie("stockservant_token");
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json, text/plain, */*");
    myHeaders.append("stockservant_token", token);
    myHeaders.append("Content-Type", "application/json")
    const resp = await fetch(`${API_DOMAIN}${url}`, {
      method: "PUT",
      headers: myHeaders,
      body: JSON.stringify(body),
    });
    const result = await resp.json();
    return result;
}

export async function deleteWithAuth(url, body) {
    const token = getCookie("stockservant_token");
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json, text/plain, */*");
    myHeaders.append("stockservant_token", token);
    myHeaders.append("Content-Type", "application/json")
    const resp = await fetch(`${API_DOMAIN}${url}`, {
      method: "DELETE",
      headers: myHeaders,
      body: JSON.stringify(body),
    });
    const result = await resp.json();
    return result;
}
