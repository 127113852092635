import { Spin } from "antd";
import { Component } from "react";
import { WelcomePage } from "../pages/welcome";
const AsyncStockScoreList = asyncComponent(() =>
  import("../pages/stock-score-list")
);
const AsyncStockDetailPage = asyncComponent(() =>
  import("../pages/stock-score-detail")
);
const AsyncMyFavorStockList = asyncComponent(() =>
    import("../pages/my-favor-stock")
);
const StockAnalyzePage = asyncComponent(() =>
    import("../pages/stock-analyze")
);

export const PageList = [
  {
    key: "home",
    name: "",
    path: "/",
    component: <WelcomePage />,
    asMenuItem: false,
  },
  {
    key: "us",
    name: "🇺🇸 美股",
    path: "/score/us",
    component: <AsyncStockScoreList market="US" />,
    asMenuItem: true,
  },
  {
    key: "cn",
    name: "🇨🇳 A股",
    path: "/score/cn",
    component: <AsyncStockScoreList market="CN" />,
    asMenuItem: true,
  },
  {
    key: "hk",
    name: "🇭🇰 港股",
    path: "/score/hk",
    component: <AsyncStockScoreList market="HK" />,
    asMenuItem: true,
  },
  {
    key: "favor",
    name: "❤️ 自选",
    path: "/score/favor",
    component: <AsyncMyFavorStockList/>,
    asMenuItem: true,
  },
  {
    key: "detail",
    name: "",
    path: "/score/detail",
    component: <AsyncStockDetailPage />,
    asMenuItem: false,
  },
  {
    key: "analyze",
    name: "",
    path: "/score/analyze",
    component: <StockAnalyzePage />,
    asMenuItem: false,
  },
];

function asyncComponent(importComponent) {
  class AsyncComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        component: null,
      };
    }

    componentDidMount() {
      importComponent().then((mod) => {
        this.setState({
          component: mod.default ? mod.default : mod,
        });
      });
    }

    render() {
      const C = this.state.component;
      return C ? (
        <C {...this.props} />
      ) : (
        <div className="flex w-full h-full items-center justify-center">
          <div className="w-32 h-32 flex items-center justify-center">
            <Spin />
          </div>
        </div>
      );
    }
  }

  return AsyncComponent;
}
